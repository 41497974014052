// @flow strict
import React from 'react';
import { withPrefix } from 'gatsby';
import styles from './Author.module.scss';

type Props = {
  author: {
    name: string,
    photo: string
  }
};

const Author = ({ author }: Props) => (
  <div className={styles['author']}>
    <div className={styles['author__pic']}>
      <img
        src={withPrefix(author.photo)}
        className={styles['author__photo']}
        width="50"
        height="50"
        alt={author.name}
      />
    </div>
    <div className={styles['author__bio']}>
      {author.name}
    </div>
  </div>
);

export default Author;

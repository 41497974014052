// @flow strict
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import styles from './Header.module.scss';
import Meta from './Meta';

type Props = {
  title: string,
  readingTime: { text: string },
  author: {
      contacts: {
        [string]: string,
      },
      photo: string,
      name: string
  },
  date: string
};

const Header = ({
  title, readingTime, date, author
}: Props) => (
    <div className={styles['header']}>
        <h1 className={styles['header__title']}>{title}</h1>
        <div className={styles['header__meta']}>
            <div className={styles['header__author']}>
                <div className={styles['header__author_date']}>
                    <Author author={author} />
                    <Meta date={date}/>
                </div>
                <Contacts contacts={author.contacts} />
            </div>
            <div className={styles['header__duration_container']}>
                <div className={styles['header__duration']}>{readingTime.text}</div>
            </div>
        </div>
    </div>
);

export default Header;

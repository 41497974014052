// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Comments from './Comments';
import Content from './Content';
import Tags from './Tags';
import Header from './Header';
import styles from './Post.module.scss';
import { useSiteMetadata } from '../../hooks';
import type { Node } from '../../types';

type Props = {
  post: Node
};

const Post = ({ post }: Props) => {
  const { body } = post;
  const { tagSlugs, slug, readingTime } = post.fields;
  const { tags, title, date } = post.frontmatter;
  const { author } = useSiteMetadata();

  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">All Articles</Link>
      <div className={styles['post__header']}>
        <Header readingTime={readingTime} author={author} date={date} title={title}/>
      </div>
      <div className={styles['post__content']}>
        <Content body={body}/>
      </div>

      <div className={styles['post__footer']}>
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
      </div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;

// @flow strict
import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import styles from './Content.module.scss';

type Props = {
  body: string
};

const Content = ({ body }: Props) => (
  <div className={styles['content']}>
    <div className={styles['content__body']} >
    <MDXProvider>
      <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
    </div>
  </div>
);

export default Content;
